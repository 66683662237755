import { UserOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Spin, Tag } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/card';
import WorkspaceHeader from '../../components/WorkspaceHeader/WorkspaceHeader';
import { VERIFY_MEMBER } from './graphql/Mutations';
import { GET_WORKSPACE_DETAILS, GET_WORKSPACE_INVITE } from './graphql/Queries';
import Workspace from './Workspace';

const SelectWorkspace = () => {
  const history = useHistory();
  const { setWorkspace, getCurrentUser, setPermission } = useContext(
    AppContext
  );

  const { userId } = getCurrentUser();
  const [selectedID, setSelectedID] = useState({
    WorkspaceID: '',
    folderID: '',
    permission: ''
  });
  const [acceptedID, setAcceptedID] = useState('');
  const [declineID, setDeclineID] = useState('');
  const { loading, data = {} } = useQuery(GET_WORKSPACE_DETAILS);

  useEffect(() => {
    if (
      selectedID.folderID &&
      selectedID.WorkspaceID &&
      selectedID.permission
    ) {
      setPermission(selectedID.permission);
      setWorkspace(selectedID.WorkspaceID);
      history.push(`/${selectedID.folderID}/folder`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedID]);

  const { loading: inviteLoading, data: inviteData = {} } = useQuery(
    GET_WORKSPACE_INVITE
  );

  const [verifyMember, { loading: verifyLoading }] = useMutation(
    VERIFY_MEMBER,
    {
      refetchQueries: [
        { query: GET_WORKSPACE_DETAILS },
        { query: GET_WORKSPACE_INVITE }
      ]
    }
  );

  const { workspace = [] } = data;
  const { workspaceInvite: { workspaceInviteData = [] } = {} } = inviteData;

  const handleAccept = ({ workspaceId, ownerId }) => {
    verifyMember({
      variables: {
        ownerId: ownerId,
        workspaceId: workspaceId,
        inviteStatus: 'ACCEPT'
      }
    });
    setAcceptedID(workspaceId);
  };
  const handleDecline = ({ workspaceId, ownerId }) => {
    verifyMember({
      variables: {
        ownerId: ownerId,
        workspaceId: workspaceId,
        inviteStatus: 'DECLINE'
      }
    });
    setDeclineID(workspaceId);
  };

  return (
    <div>
      <WorkspaceHeader />
      <Workspace hide />
      <Spin
        spinning={loading || inviteLoading}
        size="large"
        className="center-element"
      >
        {workspace
          .filter(
            (record) =>
              userId === parseInt(record?.user?.id, 10) ||
              (userId !== parseInt(record?.user?.id, 10) && !record.archived)
          )
          .map(
            ({
              id = '',
              name = '',
              logo = '',
              defaultFolder = '',
              permission = '',
              user = ''
            }) => (
              <div className="site-card-wrapper-workspace" key={id}>
                <Row>
                  <Col>
                    <Card
                      hoverable
                      onClick={() =>
                        setSelectedID({
                          folderID: defaultFolder,
                          WorkspaceID: id,
                          permission: permission
                        })
                      }
                      bordered
                      className="cardworkspace"
                    >
                      <div>
                        {logo ? (
                          <img
                            className="profile-image-70"
                            src={logo}
                            alt="avatar"
                          />
                        ) : (
                          <Avatar size={70} icon={<UserOutlined />} />
                        )}
                      </div>
                      <div className="mt-10 workspace-name">{name} </div>
                      <div className="mt-10 workspace-name">
                        {userId !== parseInt(user?.id, 10) ? (
                          <Tag color="processing">Member</Tag>
                        ) : (
                          <Tag color="success">Owner</Tag>
                        )}
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            )
          )}
        {workspaceInviteData.map(
          ({ id = '', name = '', logo = '', ownerId = '' }) => (
            <div className="site-card-wrapper-workspace" key={id}>
              <Row>
                <Col>
                  <Card hoverable bordered className="cardworkspace">
                    <div>
                      {logo ? (
                        <img
                          className="profile-image-70"
                          src={logo}
                          alt="avatar"
                        />
                      ) : (
                        <Avatar size={70} icon={<UserOutlined />} />
                      )}
                    </div>
                    <div className="mt-10 workspace-name">{name}</div>
                    <Row
                      justify="space-around"
                      align="middle"
                      className="mt-20"
                    >
                      <Col>
                        <Button
                          onClick={() =>
                            handleAccept({ workspaceId: id, ownerId: ownerId })
                          }
                          type="primary"
                          htmlType="submit"
                          loading={verifyLoading && id === acceptedID}
                          size="small"
                          className=" btn-secondary"
                        >
                          Accept
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          size="small"
                          type="primary"
                          className="btn-small"
                          onClick={() =>
                            handleDecline({ workspaceId: id, ownerId: ownerId })
                          }
                          loading={verifyLoading && id === declineID}
                        >
                          Decline
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </div>
          )
        )}
      </Spin>
    </div>
  );
};

export default SelectWorkspace;
