import { LeftOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Form, Input, message, Row, Select, Spin, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/card';
import { CREATE_TAGS, UPDATE_LINK } from './graphql/Mutations';
import { GET_LINKS_BYID, GET_TAGS } from './graphql/Queries';

const { Option } = Select;
const { Text } = Typography;

const EditLink = () => {
  const { id } = useParams();
  const history = useHistory();

  const { data = {}, loading } = useQuery(GET_LINKS_BYID, {
    fetchPolicy: 'network-only',
    variables: {
      linkId: id
    }
  });
  const { data: tagsData = {}, loading: tagsLoading } = useQuery(GET_TAGS, {
    fetchPolicy: 'network-only'
  });

  const [updateLink, { loading: updateLoading }] = useMutation(UPDATE_LINK, {
    onCompleted() {
      history.goBack();
    },
    onError(err) {
      message.error(err.message);
    }
  });

  const [createTag, { loading: createtagLoading }] = useMutation(CREATE_TAGS);

  if (loading || tagsLoading)
    return (
      <div className="center-element">
        <Spin size="large" />
      </div>
    );
  const { getTag = [] } = tagsData;

  const CreateTags = (val) => {
    createTag({
      variables: { tag: val }
    });
  };

  const onFinish = ({
    campaign = '',
    content = '',
    medium = '',
    slug = '',
    source = '',
    tags = [],
    term = '',
    notes = ''
  }) => {
    updateLink({
      variables: {
        id: id,
        slug: slug,
        utmCampaign: campaign,
        utmSource: source,
        utmMedium: medium,
        utmTerm: term,
        utmContent: content,
        tags: tags,
        notes: notes
      }
    });
    tags.forEach(CreateTags);
  };

  const {
    getLinkById: {
      shortLink = '',
      utmCampaign = '',
      utmSource = '',
      utmMedium = '',
      utmTerm = '',
      utmContent = '',
      tags = '',
      notes = '',
      destinationLink = ''
    } = {}
  } = data;

  return (
    <>
      <Card className="mb-10">
        <Row justify="space-between" align="middle">
          <Col>
            <Button
              onClick={() => history.goBack()}
              className="btn-back"
              type="text"
            >
              <LeftOutlined title="Back" />
            </Button>
            <span className="ml-5 create-link"> Update link </span>
          </Col>
        </Row>
      </Card>

      <Card loading={loading} className=" mb-20">
        <Row>
          <Col className="create-link">
            <a
              href={`${process.env.REACT_APP_GRAPHQL}${shortLink}`}
              target="blank"
            >{`${shortLink}`}</a>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="align-justify">
            <Text>{destinationLink}</Text>
          </Col>
        </Row>
      </Card>

      <Form
        name="basic"
        initialValues={{
          remember: true
        }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={10}>
          <Col span={12}>
            <Card className="mb-20" title="Choose domain name">
              <Form.Item
                label="Domain Name"
                name="domainname"
                initialValue="way2share.com"
              >
                <Select defaultValue="waytoshare.com">
                  <Option value="way2share.com">way2share.com</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Slug"
                name="slug"
                initialValue={shortLink}
                rules={[{ required: true, len: 8 }]}
              >
                <Input placeholder="Custom slug" />
              </Form.Item>
            </Card>
            <div className="mb-10">
              <Card title="Tags" className="height-200">
                <Form.Item
                  initialValue={tags || []}
                  className="inline-display mb-20 "
                  label="Tags"
                  name="tags"
                >
                  <Select mode="tags" placeholder="Tags">
                    {getTag.map(({ id: tagid = '', tag = '' }) => (
                      <Option key={tagid} value={tag}>
                        {tag}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Card>
            </div>
          </Col>
          <Col span={12}>
            <Card className="mb-20" title="Add UTMs tags">
              <Form.Item
                label="Campaign"
                initialValue={utmCampaign}
                name="campaign"
              >
                <Input placeholder="Campaign" />
              </Form.Item>
              <Form.Item label="Medium" initialValue={utmMedium} name="medium">
                <Input placeholder="Medium" />
              </Form.Item>

              <Form.Item label="Source" initialValue={utmSource} name="source">
                <Input placeholder="Source" />
              </Form.Item>
              <Form.Item
                label="Content"
                initialValue={utmContent}
                name="content"
              >
                <Input placeholder="Content" />
              </Form.Item>

              <Form.Item
                label="Term"
                initialValue={utmTerm}
                name="term"
                className="mb-0"
              >
                <Input placeholder="Term" />
              </Form.Item>
            </Card>
          </Col>
        </Row>
        <Row justify="space-around" align="middle">
          <Col span={24}>
            <Card className="mb-20" title="Add Notes">
              <Form.Item label="Notes" name="notes" initialValue={notes}>
                <TextArea rows={6} placeholder="Description of your link" />
              </Form.Item>
            </Card>
          </Col>
        </Row>

        <Form.Item>
          <Card>
            <Row justify="end" gutter={10} align="middle">
              <Col>
                <Button
                  htmlType="button"
                  className="btn-secondary-xl"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  className="btn-Primary mb-0"
                  htmlType="submit"
                  loading={updateLoading || createtagLoading}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Card>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditLink;
