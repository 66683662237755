/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  DeleteFilled,
  EditFilled,
  FolderOpenOutlined,
  LinkOutlined,
  MoreOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Col, Dropdown, Form, Menu, Popconfirm, Row, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { MODULES } from '../../common/constants';
import CommonFolderForm from '../../components/commonFolderForm/CommonFolderForm';
import {
  CREATE_FOLDER,
  DELETE_FOLDER,
  UPDATE_FOLDER
} from './graphql/Mutations';
import { GET_FOLDER_DETAIL, GET_FOLDER_LIST } from './graphql/Queries';

const Folders = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const [modalVisible, setModalVisible] = useState(false);
  const [folderName, setFolderName] = useState('');
  const [folderID, setFolderID] = useState('');
  const [deletefolderID, setDeleteFolderID] = useState('');
  const { getWorkspace, getPermission } = useContext(AppContext);
  const paramsID = getWorkspace();
  const permission = getPermission();

  if (!paramsID) {
    history.push('/');
  }

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  const [
    getFolderById,
    { data: folderDetail = {}, loading: detailLoading }
  ] = useLazyQuery(GET_FOLDER_DETAIL, {
    fetchPolicy: 'network-only',
    onCompleted() {
      form.resetFields();
    }
  });

  const [deleteFolder, { loading: deleteLoading }] = useMutation(
    DELETE_FOLDER,
    {
      onCompleted() {
        history.push(`/${paramsID}/workspace`);
      },
      refetchQueries: [
        { query: GET_FOLDER_LIST, variables: { workspaceId: paramsID } }
      ]
    }
  );

  const [createFolder, { loading: createLoading }] = useMutation(
    CREATE_FOLDER,
    {
      onCompleted(data) {
        setModalVisible(false);
        history.push(`/${data?.createFolder?.folder?.id}/folder`);
      },
      refetchQueries: [
        { query: GET_FOLDER_LIST, variables: { workspaceId: paramsID } }
      ]
    }
  );

  const [updateFolder, { loading: updateLoading }] = useMutation(
    UPDATE_FOLDER,
    {
      onCompleted() {
        setModalVisible(false);
        setFolderID('');
      },
      refetchQueries: [
        { query: GET_FOLDER_LIST, variables: { workspaceId: paramsID } }
      ]
    }
  );

  const { data = {}, loading } = useQuery(GET_FOLDER_LIST, {
    variables: { workspaceId: paramsID },
    fetchPolicy: 'network-only'
  });

  const deleteConfirm = () => {
    deleteFolder({
      variables: {
        folderId: deletefolderID
      }
    });
  };
  const {
    getFolderById: { folder: { folderName: name = '' } = {} } = {}
  } = folderDetail;

  const handleOkButton = () => {
    if (folderID) {
      updateFolder({
        variables: {
          folderName: folderName || name,
          folderId: folderID
        }
      });
    } else {
      createFolder({
        variables: {
          folderName: folderName,
          workspaceId: paramsID
        }
      });
    }
  };

  const handleCancelButton = () => {
    setModalVisible(false);
    setFolderID('');
  };

  const handleInputChange = (e) => {
    setFolderName(e.target.value);
  };

  if (loading)
    return (
      <div className="center-element mt-30">
        <Spin size="large" />
      </div>
    );
  const { folder: { folderList = [] } = {} } = data;

  const handleClick = (id) => {
    setFolderID(id);
    setModalVisible(true);
    getFolderById({
      variables: { folderId: id }
    });
  };

  return (
    <>
      <Menu
        theme="lite"
        className="sidebar-menu"
        selectedKeys={[location.pathname]}
      >
        <Menu.Item
          key={`/${paramsID}/workspace`}
          className="d-flex align-center mt-30 mb-0"
          onClick={() => history.push(`/${paramsID}/workspace`)}
        >
          <LinkOutlined className="link-icon fontsize-20" />
          <span className="sub-title fontsize-18">{MODULES.ALL_LINKS}</span>
        </Menu.Item>

        <div className="new-folder">
          <FolderOpenOutlined className="fontsize-18 mr-12" />
          <span className="sub-title">{MODULES.FOLDERS}</span>
        </div>

        {folderList.map(({ id = '', folderName: Name = '' }) => (
          <Menu.Item
            key={`/${id}/folder`}
            className="justify-text"
            onClick={() => history.push(`/${id}/folder`)}
          >
            <Row align="middle" justify="space-between">
              <Col>
                <a className="folder-div">{Name}</a>
              </Col>
              <Col className="folder-dropdown">
                {Name === 'Default' || permission === 'READ_ONLY' ? (
                  ''
                ) : (
                  <Dropdown
                    trigger={['hover']}
                    overlay={
                      <Menu>
                        <Menu.Item>
                          <Button
                            onClick={() => handleClick(id)}
                            type="link"
                            icon={<EditFilled />}
                          >
                            Rename
                          </Button>
                        </Menu.Item>
                        <Menu.Item>
                          <Popconfirm
                            title="Are you sure？"
                            onConfirm={deleteConfirm}
                            okText="Yes"
                            cancelText="No"
                            icon={
                              <QuestionCircleOutlined className="red_col" />
                            }
                          >
                            <Button
                              onClick={() => setDeleteFolderID(id)}
                              type="link"
                              loading={deleteLoading && id === deletefolderID}
                              danger
                              icon={<DeleteFilled />}
                            >
                              Delete
                            </Button>
                          </Popconfirm>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button
                      type="link"
                      icon={
                        <MoreOutlined className="folder-icon fontsize-22" />
                      }
                    />
                  </Dropdown>
                )}
              </Col>
            </Row>
          </Menu.Item>
        ))}
      </Menu>
      <div className="new-folder">
        <Button
          block
          disabled={permission === 'READ_ONLY'}
          type="primary"
          htmlType="submit"
          onClick={() => setModalVisible(true)}
        >
          New folder
        </Button>
      </div>
      <Modal
        title={folderID ? 'Rename a Folder' : 'Create a new Folder'}
        centered
        visible={modalVisible}
        onOk={form.submit}
        onCancel={handleCancelButton}
        okButtonProps={{
          loading: createLoading || updateLoading,
          className: 'btn-Primary'
        }}
        cancelButtonProps={{ className: 'btn-secondary-xl' }}
      >
        <CommonFolderForm
          inputChange={handleInputChange}
          data={folderID ? name : ''}
          loading={detailLoading}
          onFinish={handleOkButton}
          form={form}
        />
      </Modal>
    </>
  );
};

export default Folders;
