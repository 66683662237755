import React, { useContext, useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import App from './app/App';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constants';
import Error404 from './Error404';
import history from './historyData';
import Login from './modules/auth/Login';
import VerifyUser from './modules/auth/VerifyUser';
import SelectWorkspace from './modules/workspace/SelectWorkspace';
import PrivateRoute from './PrivateRoute';

const Routes = () => {
  const { initializeAuth, dispatch } = useContext(AppContext);

  useEffect(() => {
    initializeAuth();
    // Below line is disabling Eslint auto fix we don't want any value in use effect array
    // We want to call initializeAuth once. Please add this line while you working with hooks and you want to call it once.
    // eslint-disable-next-line
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={ROUTES.LOGOUT}
          render={() => {
            // eslint-disable-next-line no-undef
            window.location = '/login';
            dispatch({ type: 'LOGOUT' });
            return null;
          }}
        />
        <Route exact path={ROUTES.LOGIN} component={Login} />
        <Route exact path={ROUTES.VERIFYUSER} component={VerifyUser} />
        <Route exact path={ROUTES.ERROR} component={Error404} />
        <PrivateRoute exact path="/" component={SelectWorkspace} />
        <PrivateRoute path="*" component={App} />
      </Switch>
    </Router>
  );
};
export default Routes;
