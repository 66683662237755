import { EnvironmentOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Progress, Table } from 'antd';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { WorldMap } from 'react-svg-worldmap';
import { AppContext } from '../../AppContext';
import { percentformat } from '../../common/utils';
import Card from '../../components/Card/card';
import CommonAnalyticsCard from '../../components/commonAnalyticsCard/CommonAnalyticsCard';
import { GET_REGION } from './graphql/Queries';

const RegionData = ({ hidden, hide, show }) => {
  const { id } = useParams();
  const { getStartDate, getEndDate } = useContext(AppContext);

  const startDate = getStartDate();
  const endDate = getEndDate();

  const { data = {}, loading } = useQuery(GET_REGION, {
    fetchPolicy: 'network-only',
    variables: {
      linkId: id,
      startDate: startDate || '',
      endDate: endDate || ''
    }
  });

  const {
    getCountry: { count = '', totalCount = '', country = [] } = {}
  } = data;

  const columns = [
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country'
    },
    {
      title: 'Visit',
      dataIndex: 'visit',
      key: 'visit'
    },
    {
      title: '% Visit',
      dataIndex: 'visitpercent',
      key: 'visitpercent',
      render: (text) => (
        <Progress
          percent={percentformat(text, totalCount)}
          size="small"
          status="normal"
        />
      )
    }
  ];

  const Countrydata = country.map(
    ({ country: countryData = '', count: CountryCount = '' }) => ({
      key: countryData,
      country: countryData,
      visit: CountryCount,
      visitpercent: CountryCount
    })
  );
  const CountryData = country.map(
    ({ countryCode = '', count: CountryCount = '' }) => ({
      country: countryCode,
      value: CountryCount
    })
  );

  return (
    <>
      <CommonAnalyticsCard
        loading={loading}
        label="Region"
        count={count}
        hidden={hidden}
      >
        <EnvironmentOutlined className="fontsize-50" />
      </CommonAnalyticsCard>

      <div hidden={hide}>
        <Card title={`Region: ${count}`} className="mb-20">
          <Table
            className="mt-10"
            dataSource={Countrydata}
            columns={columns}
            pagination={false}
            loading={loading}
          />
        </Card>
      </div>

      <div className="mt-10" hidden={!show}>
        <Card loading={loading} title="Region" className="mt-10">
          <WorldMap
            color="red"
            size="xl"
            tooltipBgColor="#303179"
            valueSuffix="Click"
            valuePrefix=":"
            data={CountryData}
          />
        </Card>
      </div>
    </>
  );
};

export default RegionData;
