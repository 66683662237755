import { useMutation } from '@apollo/client';
import { Col, Row } from 'antd';
import React, { useContext, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import Logo from '../../assets/urlTags.svg';
import { ROUTES } from '../../common/constants';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/card';
import { VERIFY_USER } from './graphql/Mutations';

const VerifyUser = (props) => {
  let count = 0;
  const { initializeAuth } = useContext(AppContext);
  const [otpstate, setOtp] = useState();
  const history = useHistory();
  const { history: { location: { emailState } = {} } = {} } = props;

  if (!emailState) {
    history.push('/login');
  }
  const [verifyUser, { loading }] = useMutation(VERIFY_USER, {
    onCompleted(data) {
      const {
        verifyUser: { token, user: { refreshToken } = {} }
      } = data;
      initializeAuth(token, refreshToken);
      props.history.push(ROUTES.MAIN);
    },
    onError() {
      count += 1;
      if (count > 5) {
        props.history.push(ROUTES.LOGIN);
      }
    }
  });
  const handleChange = (otp) => setOtp(otp);

  const verifyOtpHandler = () => {
    verifyUser({
      variables: {
        email: emailState,
        otp: otpstate || ''
      }
    });
  };
  return (
    <div className="gx-login-container">
      <Card className="auth-card login-card">
        <Row align="middle" justify="center" className="mb-20">
          <Col>
            <img className="logo-icon" src={Logo} alt="Put your logo here" />
          </Col>
        </Row>
        <Row justify="center" className="mb-10">
          <Col>
            <p className="auth-text">
              A OTP has been sent to your registered email. <br />
              Enter the OTP in the below to verify
            </p>
          </Col>
        </Row>

        <div className="otp-div">
          <OtpInput
            value={otpstate}
            onChange={handleChange}
            numInputs={6}
            separator={<span className="separator">-</span>}
          />
        </div>
        <div>
          <Button
            type="primary"
            className="btn-Primary"
            onClick={verifyOtpHandler}
            loading={loading}
          >
            Verify OTP
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default VerifyUser;
