import {
  CaretDownOutlined,
  MenuOutlined,
  MoreOutlined
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Col, Drawer, Layout, Menu, Row, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import Logo from '../../../assets/urlTags.svg';
import { MODULES, ROUTES } from '../../../common/constants';
import Folders from '../../../modules/workspace/Folders';
import { GET_WORKSPACE_DETAILS_BYID } from '../../../modules/workspace/graphql/Queries';
import Workspace from '../../../modules/workspace/Workspace';

const { Header } = Layout;
const { SubMenu } = Menu;

const AppHeader = () => {
  const history = useHistory();
  const { getWorkspace } = useContext(AppContext);
  const id = getWorkspace();
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState(false);

  const [getData, { data = {}, loading }] = useLazyQuery(
    GET_WORKSPACE_DETAILS_BYID,
    {
      fetchPolicy: 'network-only'
    }
  );
  useEffect(() => {
    getData({
      variables: {
        workspaceId: id
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onChange = (text) => {
    setVisible(!visible);
    setPlacement(text);
  };

  const { getWorkspaceById: { name = '' } = {} } = data;
  return (
    <>
      <Header>
        <div className="gx-search-bar gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg">
          <div className="d-flex align-center">
            <Row>
              <Col>
                <Button
                  onClick={() => onChange('left')}
                  className="btn-back fontsize-22 hamburger"
                  type="text"
                >
                  <MenuOutlined />
                </Button>
              </Col>
              <Col />
            </Row>

            <Link to="/" className="gx-site-logo ml-10 center-logo">
              <Row align="middle">
                <Col span={4}>
                  <img
                    className="logo-icon"
                    src={Logo}
                    alt="Put your logo here"
                  />
                </Col>
              </Row>
            </Link>
          </div>
        </div>
        <Row>
          <Col>
            <Button
              onClick={() => onChange('right')}
              className="btn-back fontsize-22 hamburger"
              type="text"
            >
              <MoreOutlined />
            </Button>
          </Col>
        </Row>
        <div className="header-notification header-menu">
          <Menu mode="horizontal" triggerSubMenuAction="click">
            <Menu.Item onClick={() => history.push(ROUTES.WORKSPACE)}>
              {MODULES.WORKSPACE_MANAGEMENT}
            </Menu.Item>
            <Menu.Item onClick={() => history.push(ROUTES.USERS_PROFILE)}>
              {MODULES.USERS_MANAGEMENT}
            </Menu.Item>
            <SubMenu
              key="SubMenu"
              title={
                <Row justify="space-between" align="middle">
                  <Col>
                    {name || 'Select Workspace'}
                    <span className="ml-10 create-link">
                      <CaretDownOutlined />
                    </span>
                  </Col>
                </Row>
              }
            >
              <Spin spinning={loading}>
                <Workspace hidden Buttontype="link" />
              </Spin>
            </SubMenu>
            <Menu.Item>
              <Link to={ROUTES.LOGOUT}>Logout</Link>
            </Menu.Item>
          </Menu>
        </div>
      </Header>

      <Drawer
        placement={placement}
        closable
        onClose={onChange}
        visible={visible}
        title={
          <Link to="/" className="gx-site-logo">
            <Row align="middle">
              <Col span={4}>
                <img
                  className="logo-icon"
                  src={Logo}
                  alt="Put your logo here"
                />
              </Col>
            </Row>
          </Link>
        }
      >
        {placement === 'left' ? (
          <Folders />
        ) : (
          <div className="header-notification  ">
            <Menu theme="lite" className="sidebar-menu">
              <Menu.Item
                className="d-flex align-center mt-30 mb-0"
                onClick={() => history.push(ROUTES.WORKSPACE)}
              >
                <span className="sub-title fontsize-18">
                  {MODULES.WORKSPACE_MANAGEMENT}
                </span>
              </Menu.Item>
              <Menu.Item
                className="d-flex align-center"
                onClick={() => history.push(ROUTES.USERS_PROFILE)}
              >
                <span className="sub-title fontsize-18">
                  {MODULES.USERS_MANAGEMENT}
                </span>
              </Menu.Item>
              <SubMenu
                className="d-flex align-center"
                key="SubMenu"
                title={
                  <Row justify="space-between" align="middle">
                    <Col>
                      <span className="sub-title fontsize-18">
                        {name || 'Select Workspace'}
                      </span>
                      <span className="ml-10 create-link">
                        <CaretDownOutlined />
                      </span>
                    </Col>
                  </Row>
                }
              >
                <Spin spinning={loading} className="pl-50 ">
                  <Workspace hidden Buttontype="link" />
                </Spin>
              </SubMenu>
              <Menu.Item className="d-flex align-center ">
                <span className="sub-title fontsize-18">
                  <Link to={ROUTES.LOGOUT}>Logout</Link>
                </span>
              </Menu.Item>
            </Menu>
          </div>
        )}
      </Drawer>
    </>
  );
};
export default AppHeader;
