import { useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import CommonForm from '../../components/commonForm/CommonForm';
import { LOGIN } from './graphql/Mutations';

const Login = (props) => {
  const [emailState, setEmailState] = useState('');
  const history = useHistory();

  const { getToken } = useContext(AppContext);
  const idToken = getToken();

  useEffect(() => {
    if (idToken) {
      history.push(ROUTES.MAIN);
    } else {
      history.push(ROUTES.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [login, { loading }] = useMutation(LOGIN, {
    onCompleted() {
      props.history.replace({
        pathname: ROUTES.VERIFYUSER,
        emailState: emailState
      });
    }
  });

  const onFinish = (values) => {
    const { email } = values;
    login({
      variables: {
        email: email
      }
    });
    setEmailState(email);
  };
  return (
    <>
      <CommonForm btnText="Login" onFinish={onFinish} loading={loading} />
    </>
  );
};

export default Login;
