import { useQuery } from '@apollo/client';
import { Col, Row, Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import Card from '../../components/Card/card';
import CommonLinkTable from '../../components/commonLinkTable/CommonLinkTable';
import { GET_ALL_LINKS } from '../Link/graphql/Queries';
import './dashboard.less';

const Dashboard = () => {
  const { id } = useParams();
  const { setWorkspace } = useContext(AppContext);
  useEffect(() => {
    setWorkspace(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const { data = {}, loading: dataLoading } = useQuery(GET_ALL_LINKS, {
    fetchPolicy: 'network-only',
    variables: {
      workspaceId: id
    }
  });
  const { getLinkByWorkspaceId = [] } = data;

  if (dataLoading)
    return (
      <div className="center-element">
        <Spin size="large" />
      </div>
    );
  return (
    <>
      <Card>
        <Row justify="space-between" align="middle">
          <Col className="create-link">All links</Col>
        </Row>
      </Card>

      <CommonLinkTable
        data={getLinkByWorkspaceId}
        id={id}
        dataLoading={dataLoading}
        filter="all"
      />
    </>
  );
};

export default Dashboard;
