import { LeftOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Form, Input, Radio, Row, Select } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { formValidatorRules } from '../../common/utils';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/card';
import { INVITE_MEMBER } from './graphql/Mutations';
import { GET_WORKSPACE_DETAILS } from './graphql/Queries';

const { Option } = Select;

const InviteMember = ({ visible, workspaceId }) => {
  const [form] = Form.useForm();
  const { required, email } = formValidatorRules;

  const { getCurrentUser } = useContext(AppContext);
  const { userId } = getCurrentUser();

  const { data = {}, loading } = useQuery(GET_WORKSPACE_DETAILS, {
    fetchPolicy: 'network-only'
  });

  const { workspace = [] } = data;
  const [inviteMember, { loading: inviteLoading }] = useMutation(
    INVITE_MEMBER,
    {
      onCompleted() {
        form.resetFields();
        visible();
      }
    }
  );

  const onFinish = (value) => {
    const {
      workspace: selectedWorkspace = '',
      email: mail = '',
      permission = ''
    } = value;
    inviteMember({
      variables: {
        email: mail,
        workspaceId: workspaceId || selectedWorkspace,
        permission: permission || 'WRITE'
      }
    });
  };

  const handleCancel = () => {
    form.resetFields();
    visible();
  };
  return (
    <Row justify="center">
      <Col span={15}>
        <Card
          loading={loading}
          title={
            <>
              <Row justify="space-between" align="middle">
                <Col>
                  <Button
                    onClick={() => visible()}
                    className="btn-back"
                    type="text"
                  >
                    <LeftOutlined title="Back" />
                  </Button>
                  <span className="ml-5"> Invite member</span>
                </Col>
              </Row>
            </>
          }
          className="mb-20"
        >
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true
            }}
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              label="Select Workspace"
              name="workspace"
              rules={workspaceId ? '' : [{ required }]}
            >
              <Select
                placeholder="Select a Workspace"
                defaultValue={workspaceId}
                value={workspaceId}
              >
                {workspace.map(({ id = '', name = '', user = {} }) =>
                  // eslint-disable-next-line no-nested-ternary
                  workspaceId ? (
                    workspaceId === id ? (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ) : (
                      ''
                    )
                  ) : userId === parseInt(user?.id, 10) ? (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ) : (
                    ''
                  )
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true }, email]}
            >
              <Input suffix={<UserOutlined />} placeholder="Email" />
            </Form.Item>
            <Form.Item label="Permission" name="permission">
              <Radio.Group
                defaultValue="WRITE"
                buttonStyle="solid"
                rules={[required]}
              >
                <Radio.Button value="WRITE">WRITE</Radio.Button>
                <Radio.Button value="READ_ONLY">READ ONLY</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item className="mb-0">
              <Row justify="end" gutter={10}>
                <Col>
                  <Button
                    htmlType="button"
                    className="btn-secondary-xl"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    loading={inviteLoading}
                    className="btn-Primary"
                    htmlType="submit"
                  >
                    Invite
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default InviteMember;
