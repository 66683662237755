import {
  BarChartOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  PlayCircleOutlined,
  QrcodeOutlined,
  QuestionCircleOutlined,
  StopOutlined
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, message, Modal, Popconfirm, Row, Space, Table } from 'antd';
import QRCode from 'qrcode.react';
import React, { useContext, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import {
  CHANGE_LINK_STATUS,
  DELETE_LINK
} from '../../modules/Link/graphql/Mutations';
import {
  GET_ALL_LINKS,
  GET_LINKS_BYFOLDER
} from '../../modules/Link/graphql/Queries';

const CommonLinkTable = ({ data = [], id, dataLoading, filter }) => {
  let userdata = '';
  const history = useHistory();
  const { getPermission } = useContext(AppContext);
  const permission = getPermission();

  const [modalVisible, setModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [changeStatusId, setChangeStatusId] = useState('');
  const [Status, setStatus] = useState('');
  const [link, setLink] = useState('');

  const [deleteLink, { loading: deleteLoading }] = useMutation(DELETE_LINK);
  const [redirect, { loading: redirectLoading }] = useMutation(
    CHANGE_LINK_STATUS
  );

  const deleteConfirm = () => {
    deleteLink({
      variables: { id: deleteId },
      refetchQueries: [
        filter
          ? { query: GET_ALL_LINKS, variables: { workspaceId: id } }
          : { query: GET_LINKS_BYFOLDER, variables: { folderId: id } }
      ]
    });
  };

  const changeStatusConfirm = () => {
    redirect({
      variables: {
        id: changeStatusId,
        status: Status
      },
      refetchQueries: [
        filter
          ? { query: GET_ALL_LINKS, variables: { workspaceId: id } }
          : { query: GET_LINKS_BYFOLDER, variables: { folderId: id } }
      ]
    });
  };

  const handleClick = (shortLink) => {
    setLink(shortLink);
    setModalVisible(true);
  };

  userdata = data.map(
    ({
      id: linkId = '',
      shortLink = '',
      destinationLink = '',
      clicks = '',
      redirectStatus = '',
      createdAt = ''
    }) => ({
      key: linkId,
      shortlink: (
        <a
          className={redirectStatus === 'DISABLE' ? 'line-through' : ''}
          href={`${process.env.REACT_APP_GRAPHQL}${shortLink}`}
          target="blank"
        >{`${shortLink}`}</a>
      ),
      destinationLink: destinationLink,
      clicks: clicks || 0,
      CreatedAt: createdAt,
      action: (
        <Space size="middle">
          <Button
            className="btn_link"
            onClick={() => history.push(`/${linkId}/analytics`)}
            type="link"
          >
            <BarChartOutlined title="Analytics" />
          </Button>
          <Button
            className="btn_link"
            onClick={() => handleClick(shortLink)}
            type="link"
          >
            <QrcodeOutlined title="QR Code" />
          </Button>
          <CopyToClipboard
            text={`${process.env.REACT_APP_GRAPHQL}${shortLink}`}
            onCopy={() => message.success('Copied.')}
          >
            <Button className="btn_link" type="link">
              <CopyOutlined title="Copy to Clipboard" />
            </Button>
          </CopyToClipboard>
          <Button
            className="btn_link"
            disabled={permission === 'READ_ONLY'}
            onClick={() => history.push(`/link/${linkId}/edit`)}
            type="link"
          >
            <EditOutlined title="Edit" />
          </Button>
          <Popconfirm
            disabled={permission === 'READ_ONLY'}
            title="Are you sure？"
            onConfirm={deleteConfirm}
            okText="Yes"
            cancelText="No"
            icon={<QuestionCircleOutlined className="red_col" />}
          >
            <Button
              onClick={() => setDeleteId(linkId)}
              type="link"
              loading={deleteLoading && linkId === deleteId}
              danger
              disabled={permission === 'READ_ONLY'}
            >
              <DeleteOutlined title="Delete" />
            </Button>
          </Popconfirm>
          <Popconfirm
            disabled={permission === 'READ_ONLY'}
            title="Are you sure？"
            onConfirm={changeStatusConfirm}
            okText="Yes"
            cancelText="No"
            icon={<QuestionCircleOutlined />}
          >
            <Button
              disabled={permission === 'READ_ONLY'}
              onClick={() => setChangeStatusId(linkId)}
              type="link"
              loading={redirectLoading && linkId === changeStatusId}
            >
              {redirectStatus === 'DISABLE' ? (
                <PlayCircleOutlined
                  title="Enable"
                  onClick={() => setStatus('ENABLE')}
                />
              ) : (
                <StopOutlined
                  title="Disable"
                  onClick={() => setStatus('DISABLE')}
                />
              )}
            </Button>
          </Popconfirm>
        </Space>
      )
    })
  );

  const columns = [
    {
      title: 'Short link',
      dataIndex: 'shortlink',
      key: 'shortlink',
      fixed: 'left',
      width: 75
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
      fixed: 'left',
      width: 60
    },
    {
      title: 'Destination link',
      dataIndex: 'destinationLink',
      key: 'destinationLink',
      ellipsis: true,
      fixed: 'left',
      width: '30%'
    },
    {
      title: 'Created on',
      dataIndex: 'CreatedAt',
      key: 'CreatedAt',
      fixed: 'left',
      width: 75
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 200
    }
  ];

  return (
    <>
      <Table
        className="mt-20 mb-20"
        dataSource={userdata}
        columns={columns}
        pagination={false}
        loading={dataLoading}
      />
      <Modal
        title="QR Code"
        centered
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ className: 'btn-secondary-xl' }}
      >
        <Row justify="center">
          <QRCode value={`${process.env.REACT_APP_GRAPHQL}${link}`} />
        </Row>
      </Modal>
    </>
  );
};

export default CommonLinkTable;
