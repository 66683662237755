import { Col, Row } from 'antd';
import React from 'react';
import BrowserData from './BrowserData';
import ClicksChart from './ClicksChart';
import DeviceData from './DeviceData';
import OsData from './OsData';
import RegionData from './RegionData';

const AnalyticsCard = () => {
  return (
    <>
      <div>
        <Row gutter={10} justify="space-between" className="mt-10">
          <Col span={4}>
            <ClicksChart hide />
          </Col>
          <Col span={4}>
            <BrowserData hide />
          </Col>
          <Col span={4}>
            <DeviceData hide />
          </Col>
          <Col span={4}>
            <RegionData hide />
          </Col>
          <Col span={4}>
            <OsData hide />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AnalyticsCard;
