import jwtDecode from 'jwt-decode';
import React, { createContext, useReducer } from 'react';
import client from './apollo';
import api from './common/api';
import { REFRESH_TOKEN, TOKEN, USER, WORKSPACE } from './common/constants';

const getLoggedInUser = () => {
  // eslint-disable-next-line no-undef
  let loggedInUser = localStorage.getItem(USER);
  loggedInUser = loggedInUser ? JSON.parse(loggedInUser) : null;

  return loggedInUser;
};

const initialState = {
  currentUser: getLoggedInUser() || {},
  // eslint-disable-next-line no-undef
  authToken: localStorage.getItem(TOKEN),
  refreshToken: '',
  currentWorkspace: '',
  permission: '',
  startDate: '',
  endDate: ''
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      // eslint-disable-next-line no-case-declarations
      const user = action.data || {};

      // eslint-disable-next-line no-undef
      localStorage.setItem(
        USER,
        user && Object.keys(user).length ? JSON.stringify(user) : null
      );
      return { ...state, currentUser: { ...user } };
    case 'SET_CURRENT_ROLE':
      return { ...state, currentRole: action.data };
    case 'SET_CURRENT_WORKSPACE':
      // eslint-disable-next-line no-undef
      localStorage.setItem(WORKSPACE, action.data);
      return { ...state, currentWorkspace: action.data };
    case 'SET_PERMISSION':
      return { ...state, permission: action.data };
    case 'SET_START_DATE':
      return { ...state, startDate: action.data };
    case 'SET_END_DATE':
      return { ...state, endDate: action.data };
    case 'LOGOUT':
      delete api.defaults.headers.common.Authorization;
      // eslint-disable-next-line no-undef
      localStorage.removeItem(USER);
      // eslint-disable-next-line no-undef
      localStorage.removeItem(TOKEN);
      // eslint-disable-next-line no-undef
      localStorage.removeItem(WORKSPACE);
      // eslint-disable-next-line no-undef
      localStorage.removeItem(REFRESH_TOKEN);

      client.clearStore();
      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        user: {}
      };
    case 'SET_FETCHING_USER_DETAILS':
      return { ...state, fetchingUserDetails: action.data };
    case 'SET_AUTHENTICATED':
      return { ...state, authenticated: action.data };
    case 'SET_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage.setItem(TOKEN, action.data);
      return { ...state, authToken: action.data };
    case 'SET_REFRESH_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage.setItem(REFRESH_TOKEN, action.data);
      return { ...state, refreshToken: action.data };
    case 'SET_INITIAL_SHOW_ALL_FIELDS_STATUS':
      return { ...state, initialShowAllFieldStatus: action.data };
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {}
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = () => {
    // eslint-disable-next-line no-undef
    return localStorage.getItem(TOKEN) || null;
  };

  const getCurrentUser = () => {
    // eslint-disable-next-line no-undef
    return localStorage.getItem(USER)
      ? // eslint-disable-next-line no-undef
        JSON.parse(localStorage.getItem(USER))
      : {};
  };

  const getCurrentUserRole = () => {
    const loggedInUser = getLoggedInUser();
    return (loggedInUser && loggedInUser.roles && loggedInUser.roles[0]) || '';
  };

  const isAuthenticated = () => {
    return state.authenticated;
  };

  const initializeAuth = (authToken, refreshToken) => {
    const token = authToken || getToken();
    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      const userData = jwtDecode(token);
      dispatch({ type: 'SET_TOKEN', data: token });
      dispatch({ type: 'SET_AUTHENTICATED', data: true });
      dispatch({ type: 'SET_CURRENT_USER', data: userData });
      if (userData && userData.roles && userData.roles[0]) {
        dispatch({ type: 'SET_CURRENT_ROLE', data: userData.roles[0] });
      }
      if (refreshToken) {
        dispatch({ type: 'SET_REFRESH_TOKEN', data: refreshToken });
      }
    }
  };

  const setWorkspace = (workspaceId = '') => {
    if (workspaceId) {
      dispatch({ type: 'SET_CURRENT_WORKSPACE', data: workspaceId });
    }
  };

  const getWorkspace = () => {
    // eslint-disable-next-line no-undef
    return localStorage.getItem(WORKSPACE) || null;
  };
  const setPermission = (permission = '') => {
    if (permission) {
      dispatch({ type: 'SET_PERMISSION', data: permission });
    }
  };

  const getPermission = () => {
    return state.permission;
  };
  const setStartDate = (startDate = '') => {
    dispatch({ type: 'SET_START_DATE', data: startDate });
  };
  const getStartDate = () => {
    return state.startDate;
  };
  const setEndDate = (endDate = '') => {
    dispatch({ type: 'SET_END_DATE', data: endDate });
  };
  const getEndDate = () => {
    return state.endDate;
  };
  const value = {
    state,
    dispatch,
    isAuthenticated,
    getToken,
    initializeAuth,
    getCurrentUserRole,
    getCurrentUser,
    setWorkspace,
    getWorkspace,
    setPermission,
    getPermission,
    setStartDate,
    setEndDate,
    getStartDate,
    getEndDate
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
