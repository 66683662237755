import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_LINKS_BYFOLDER = gql`
  query getLinkByFolderId($folderId: ID!) {
    getLinkByFolderId(folderId: $folderId) {
      id
      shortLink
      destinationLink
      clicks
      createdAt
      utmCampaign
      utmSource
      utmMedium
      utmTerm
      utmContent
      tags
      folderId
      workspaceId
      redirectStatus
    }
  }
`;
export const GET_ALL_LINKS = gql`
  query getLinkByWorkspaceId($workspaceId: ID!) {
    getLinkByWorkspaceId(workspaceId: $workspaceId) {
      id
      shortLink
      destinationLink
      clicks
      createdAt
      utmCampaign
      utmSource
      utmMedium
      utmTerm
      utmContent
      tags
      folderId
      workspaceId
      redirectStatus
    }
  }
`;

export const GET_LINKS_BYID = gql`
  query getLinkById($linkId: ID!) {
    getLinkById(linkId: $linkId) {
      id
      shortLink
      destinationLink
      utmCampaign
      utmSource
      utmMedium
      utmTerm
      utmContent
      tags
      folderId
      workspaceId
      redirectStatus
      notes
    }
  }
`;

export const GET_TAGS = gql`
  query getTag {
    getTag {
      id
      tag
      createdBy
      message
    }
  }
`;
