import { LeftOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Col, Form, Menu, message, Modal, Row, Spin, Typography } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/card';
import CommonWorkspaceForm from '../../components/commonWorkspaceForm/CommonWorkspaceForm';
import { GET_SIGNED_URL } from '../users/graphql/Mutations';
import { CREATE_WORKSPACE, UPDATE_WORKSPACE } from './graphql/Mutations';
import {
  GET_WORKSPACE_DETAILS,
  GET_WORKSPACE_DETAILS_BYID
} from './graphql/Queries';

const { Title } = Typography;

const Workspace = ({ hide, hidden, Buttontype }) => {
  const [form] = Form.useForm();
  const history = useHistory();

  const { setWorkspace, getCurrentUser, setPermission } = useContext(
    AppContext
  );
  const { userId } = getCurrentUser();

  const [modalVisible, setModalVisible] = useState(false);
  const [workspaceName, setWorkspaceName] = useState('');
  const [currentFile, setCurrentFile] = useState('');
  const [filePreview, setfilePreview] = useState();

  const { id: paramsID } = useParams();

  const [getData, { data: workspacedata = {}, loading }] = useLazyQuery(
    GET_WORKSPACE_DETAILS_BYID,
    {
      fetchPolicy: 'network-only'
    }
  );

  const {
    getWorkspaceById: { name: workspacename = '', logo = '' } = {}
  } = workspacedata;

  useEffect(() => {
    if (paramsID) {
      getData({
        variables: {
          workspaceId: paramsID
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsID]);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  const [updateWorkspace] = useMutation(UPDATE_WORKSPACE, {
    onCompleted() {
      history.push(ROUTES.WORKSPACE);
    }
  });

  const [createWorkspace, { loading: createLoading }] = useMutation(
    CREATE_WORKSPACE,
    {
      onCompleted(data) {
        const {
          createWorkspace: {
            workspace: { id = '', defaultFolder = '', permission = '' } = {}
          } = {}
        } = data;
        setModalVisible(false);
        setPermission(permission);
        setWorkspace(id);
        history.push(`/${defaultFolder}/folder`);
      },
      refetchQueries: [{ query: GET_WORKSPACE_DETAILS }]
    }
  );
  const [getSignedURL] = useMutation(GET_SIGNED_URL, {
    async onCompleted(data) {
      const { getSignedUrl: { signedURL = '', fileURL = '' } = {} } = data;

      const options = {
        headers: {
          'Content-Type': currentFile.type,
          'Access-Control-Allow-Origin': 'Origin',
          'Access-Control-Allow-Methods': 'PUT',
          'x-amz-acl': 'public-read'
        }
      };

      const uploadFileOnS3 = await axios.put(signedURL, currentFile, options);
      if (uploadFileOnS3.status === 200) {
        if (paramsID && workspaceName) {
          updateWorkspace({
            variables: {
              id: paramsID,
              logo: fileURL,
              name: workspaceName
            },
            refetchQueries: [{ query: GET_WORKSPACE_DETAILS }]
          });
        } else if (paramsID) {
          updateWorkspace({
            variables: {
              id: paramsID,
              logo: fileURL
            },
            refetchQueries: [{ query: GET_WORKSPACE_DETAILS }]
          });
        } else {
          createWorkspace({
            variables: { logo: fileURL, name: workspaceName }
          });
        }
      }
    }
  });

  const { data = {}, loading: loadingData } = useQuery(GET_WORKSPACE_DETAILS, {
    fetchPolicy: 'network-only'
  });
  const { workspace = [] } = data;

  const handleOkButton = () => {
    if (currentFile) {
      const { name, type } = currentFile;
      getSignedURL({
        variables: {
          fileName: name,
          fileType: type
        }
      });
    } else if (paramsID && workspaceName) {
      updateWorkspace({
        variables: {
          id: paramsID,
          name: workspaceName
        },
        refetchQueries: [{ query: GET_WORKSPACE_DETAILS }]
      });
    } else {
      createWorkspace({
        variables: { name: workspaceName }
      });
    }
  };
  const handleChange = (e) => {
    if (
      e.target.files[0].type === 'image/jpeg' ||
      e.target.files[0].type === 'image/png' ||
      e.target.files[0].type === 'image/jpg'
    ) {
      setCurrentFile(e.target.files[0]);
      // eslint-disable-next-line no-undef
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setfilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      message.error(`${e.target.files[0].name} is not valid type.`);
    }
  };

  const handleInputChange = (e) => {
    setWorkspaceName(e.target.value);
  };

  const onClick = ({ id, defaultFolder, permission }) => {
    setWorkspace(id);
    setPermission(permission);
    history.push(`/${defaultFolder}/folder`);
  };

  const onCancel = () => {
    setfilePreview();
    setModalVisible(false);
    setCurrentFile();
  };
  if (paramsID) {
    return (
      <Row justify="center">
        <Col span={15}>
          <Card
            loading={loading}
            className="mb-20"
            title={
              <>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Button
                      onClick={() => history.goBack()}
                      className="btn-back"
                      type="text"
                    >
                      <LeftOutlined title="Back" />
                    </Button>
                    <span className="ml-5"> Update workspace</span>
                  </Col>
                </Row>
              </>
            }
            bordered={false}
          >
            <CommonWorkspaceForm
              fileChange={handleChange}
              inputChange={handleInputChange}
              workspaceName={workspacename}
              logo={filePreview || logo}
            />

            <Button
              className="btn-Primary btnsave"
              onClick={handleOkButton}
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
            <Button
              type="primary "
              className="btn-secondary-xl btnsave"
              onClick={() => history.push(ROUTES.WORKSPACE)}
            >
              Cancel
            </Button>
          </Card>
        </Col>
      </Row>
    );
  }
  return (
    <>
      <Row justify="space-around" align="middle" className="nav-row">
        <Col hidden={hidden}>
          <Title level={3}>Select your workspace</Title>
          <Title level={5}>
            Please select a workspace from the below or create a new workspace
          </Title>
        </Col>
        <Col>
          <Spin spinning={loadingData}>
            <Menu mode="inline" className="sidebar-menu ">
              {workspace
                .filter(
                  (record) =>
                    userId === parseInt(record.user?.id, 10) ||
                    (userId !== parseInt(record.user?.id, 10) &&
                      !record.archived)
                )
                .map(
                  ({
                    id = '',
                    name = '',
                    defaultFolder = '',
                    permission = ''
                  }) => (
                    <Menu.Item hidden={hide} key={id}>
                      <p
                        onClick={() =>
                          onClick({
                            id,
                            defaultFolder,
                            permission
                          })
                        }
                      >
                        {name}
                      </p>
                    </Menu.Item>
                  )
                )}
            </Menu>

            <Button
              type={Buttontype || 'primary'}
              className="btn-Primary"
              icon={Buttontype ? <PlusCircleOutlined /> : ''}
              onClick={() => setModalVisible(true)}
            >
              Add Workspace
            </Button>
          </Spin>
        </Col>
      </Row>

      <Modal
        title="Create a New Workspace"
        centered
        visible={modalVisible}
        onOk={form.submit}
        onCancel={onCancel}
        okButtonProps={{ loading: createLoading, className: 'btn-Primary' }}
        cancelButtonProps={{ className: 'btn-secondary-xl' }}
      >
        <CommonWorkspaceForm
          fileChange={handleChange}
          inputChange={handleInputChange}
          onFinish={handleOkButton}
          data={form}
          logo={filePreview}
        />
      </Modal>
    </>
  );
};

export default Workspace;
