import { GlobalOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Progress, Table } from 'antd';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { percentformat } from '../../common/utils';
import Card from '../../components/Card/card';
import CommonAnalyticsCard from '../../components/commonAnalyticsCard/CommonAnalyticsCard';
import { GET_BROWSER } from './graphql/Queries';

const BrowserData = ({ hide, hidden }) => {
  const { id } = useParams();
  const { getStartDate, getEndDate } = useContext(AppContext);
  const startDate = getStartDate();
  const endDate = getEndDate();

  const { data = {}, loading: browserloading } = useQuery(GET_BROWSER, {
    fetchPolicy: 'network-only',
    variables: {
      linkId: id,
      startDate: startDate,
      endDate: endDate
    }
  });

  const {
    getBrowsers: { count = '', browser = [], totalCount = '' } = {}
  } = data;

  const columns = [
    {
      title: 'Browser',
      dataIndex: 'browser',
      key: 'browser'
    },
    {
      title: 'Visit',
      dataIndex: 'visit',
      key: 'visit'
    },
    {
      title: '% Visit',
      dataIndex: 'visitpercent',
      key: 'visitpercent',
      render: (text) => (
        <Progress
          percent={percentformat(text, totalCount)}
          size="small"
          status="normal"
        />
      )
    }
  ];

  const userdata = browser.map(
    ({ browser: browserData = '', count: clickCount = '' }) => ({
      key: browserData,
      browser: browserData,
      visit: clickCount,
      visitpercent: clickCount
    })
  );

  return (
    <>
      <CommonAnalyticsCard
        loading={browserloading}
        label="Browser"
        count={count}
        hidden={hidden}
      >
        <GlobalOutlined className="fontsize-50" />
      </CommonAnalyticsCard>

      <div hidden={hide}>
        <Card title={`Browser: ${count}`}>
          <Table
            className="mt-10"
            dataSource={userdata}
            columns={columns}
            pagination={false}
            loading={browserloading}
          />
        </Card>
      </div>
    </>
  );
};

export default BrowserData;
