import { gql } from '@apollo/client';

export const CREATE_LINK = gql`
  mutation createLink($URL: String!, $folderId: ID!, $workspaceId: ID!) {
    createLink(
      input: { URL: $URL, folderId: $folderId, workspaceId: $workspaceId }
    ) {
      shortLink
      message
    }
  }
`;

export const DELETE_LINK = gql`
  mutation deleteLink($id: ID!) {
    deleteLink(id: $id) {
      status
      message
    }
  }
`;

export const UPDATE_LINK = gql`
  mutation updateLink(
    $id: ID!
    $slug: String!
    $utmCampaign: String
    $utmSource: String
    $utmMedium: String
    $utmTerm: String
    $utmContent: String
    $tags: [String]
    $notes: String
  ) {
    updateLink(
      input: {
        id: $id
        slug: $slug
        utmCampaign: $utmCampaign
        utmSource: $utmSource
        utmMedium: $utmMedium
        utmTerm: $utmTerm
        utmContent: $utmContent
        tags: $tags
        notes: $notes
      }
    ) {
      shortLink
      message
    }
  }
`;

export const CHANGE_LINK_STATUS = gql`
  mutation redirect($id: ID!, $status: Status!) {
    redirect(input: { id: $id, status: $status }) {
      message
    }
  }
`;

export const CREATE_TAGS = gql`
  mutation createTag($tag: String!) {
    createTag(tag: $tag) {
      tag
    }
  }
`;
