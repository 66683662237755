import {
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  QuestionCircleOutlined,
  UserAddOutlined
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Badge, Button, Col, Popconfirm, Row, Space, Table } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Card from '../../components/Card/card';
import { DELETE_INVITE, DELETE_MEMBER } from './graphql/Mutations';
import { GET_ALL_MEMBERS, GET_WORKSPACE_DETAILS_BYID } from './graphql/Queries';
import InviteMember from './InviteMember';

const WorkspaceMember = (props) => {
  let userdata = '';
  const history = useHistory();
  const { id } = useParams();
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [Status, setStatus] = useState('');
  const [workspaceID, setWorkspaceID] = useState('');

  const { data = {}, loading, refetch } = useQuery(GET_ALL_MEMBERS, {
    variables: { workspaceId: id },
    fetchPolicy: 'network-only'
  });
  const { data: workspaceData = {}, loading: worskspaceLoading } = useQuery(
    GET_WORKSPACE_DETAILS_BYID,
    {
      variables: { workspaceId: id },
      fetchPolicy: 'network-only'
    }
  );
  const [deleteMember, { loading: mdeleteLoading }] = useMutation(
    DELETE_MEMBER
  );
  const [deleteInvite, { loading: ideleteLoading }] = useMutation(
    DELETE_INVITE
  );

  const deleteConfirm = () => {
    if (Status === 'ACCEPTED') {
      deleteMember({
        variables: {
          memberId: deleteId,
          workspaceId: workspaceID
        },
        refetchQueries: [
          { query: GET_ALL_MEMBERS, variables: { workspaceId: id } }
        ]
      });
    } else {
      deleteInvite({
        variables: { inviteId: deleteId },
        refetchQueries: [
          {
            query: GET_ALL_MEMBERS,
            variables: { workspaceId: id }
          }
        ]
      });
    }
  };

  const handleClick = ({ selectedid, status, workspaceId }) => {
    setDeleteId(selectedid);
    setStatus(status);
    setWorkspaceID(workspaceId);
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Permission',
      dataIndex: 'permission',
      key: 'permission'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) =>
        // eslint-disable-next-line no-nested-ternary
        text === 'ACCEPTED' ? (
          <Badge status="success" text={text} />
        ) : text === 'PENDING' ? (
          <Badge status="warning" text={text} />
        ) : (
          <Badge status="error" text={text} />
        )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 80
    }
  ];
  const { getWorkspaceById: { name = '', archived = '' } = {} } = workspaceData;
  const { getMemberByWorkspaceId: { user = [] } = {} } = data;

  const handleEditClick = ({ memberId, status, workspaceId, inviteId }) => {
    props.history.push({
      pathname: `member/${memberId || inviteId}/edit`,
      status: status,
      workspaceId: workspaceId
    });
  };

  userdata = user.map(
    ({
      firstName = '',
      email = '',
      status = '',
      permission = '',
      memberId = '',
      workspaceId = '',
      inviteId = ''
    }) => ({
      key: email,
      name: firstName || '-',
      email: email,
      permission: permission,
      status: status,
      action: (
        <Space size="middle">
          {status !== 'REJECTED' && (
            <Button
              className="btn_link"
              onClick={() =>
                handleEditClick({
                  memberId: memberId,
                  status: status,
                  workspaceId: workspaceId,
                  inviteId: inviteId
                })
              }
              type="link"
              disabled={archived}
            >
              <EditOutlined title="Edit" />
            </Button>
          )}
          {status === 'REJECTED' && (
            <Button
              className="btn_link"
              onClick={() =>
                handleEditClick({
                  memberId: memberId,
                  status: status,
                  workspaceId: workspaceId,
                  inviteId: inviteId
                })
              }
              type="link"
              disabled={archived}
            >
              <UserAddOutlined title="Re-invite" />
            </Button>
          )}
          <Popconfirm
            title="Are you sure？"
            onConfirm={deleteConfirm}
            okText="Yes"
            cancelText="No"
            icon={<QuestionCircleOutlined className="red_col" />}
            disabled={archived}
          >
            <Button
              onClick={() =>
                handleClick({
                  selectedid: memberId || inviteId,
                  status: status,
                  workspaceId: workspaceId
                })
              }
              type="link"
              loading={
                (mdeleteLoading || ideleteLoading) &&
                (memberId || inviteId) === deleteId
              }
              danger
              disabled={archived}
            >
              <DeleteOutlined title="Delete" />
            </Button>
          </Popconfirm>
        </Space>
      )
    })
  );

  const changeVisibility = () => {
    setModalVisible(false);
    refetch();
  };

  return (
    <>
      <div hidden={modalVisible}>
        <Card
          className="mb-20 member-table"
          loading={worskspaceLoading}
          title={
            <>
              <Row justify="space-between" align="middle">
                <Col>
                  <Button
                    onClick={() => history.goBack()}
                    className="btn-back"
                    type="text"
                  >
                    <LeftOutlined title="Back" />
                  </Button>
                  <span className="ml-5"> {name}</span>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => setModalVisible(true)}
                    disabled={archived}
                  >
                    Invite member
                  </Button>
                </Col>
              </Row>
            </>
          }
        >
          <Table
            className="mt-10"
            dataSource={userdata}
            columns={columns}
            pagination={false}
            loading={loading}
          />
        </Card>
      </div>
      <div hidden={!modalVisible}>
        <InviteMember workspaceId={id} visible={changeVisibility} />
      </div>
    </>
  );
};

export default WorkspaceMember;
