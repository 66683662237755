import { DesktopOutlined } from '@ant-design/icons';
import { Col, Layout, Menu, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/urlTags.svg';
import { ROUTES } from '../../common/constants';
import Workspace from '../../modules/workspace/Workspace';

const { Header } = Layout;
const { SubMenu } = Menu;
const WorkspaceHeader = () => {
  return (
    <Header>
      <div className="ml-75">
        <Link to="/">
          <Row align="middle" justify="center">
            <Col>
              <img className="logo-icon" src={Logo} alt="Put your logo here" />
            </Col>
          </Row>
        </Link>
      </div>
      <Menu mode="horizontal" className="mr-75">
        <SubMenu icon={<DesktopOutlined />} title="Select Workspace">
          <Workspace hidden Buttontype="link" />
        </SubMenu>
        <Menu.Item>
          <Link to={ROUTES.LOGOUT}>Logout</Link>
        </Menu.Item>
      </Menu>
    </Header>
  );
};
export default WorkspaceHeader;
