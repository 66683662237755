import { UserOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Col, Form, Input, message, Row } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/card';
import { GET_SIGNED_URL, UPDATE_PROFILE } from './graphql/Mutations';
import { GET_USER_DETAILS } from './graphql/Queries';

const UserProfile = () => {
  const [form] = Form.useForm();
  const [disabledprofile, setDisabledprofile] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [currentFile, setCurrentFile] = useState();
  const [filePreview, setfilePreview] = useState();

  const { data: userdata = {}, loading } = useQuery(GET_USER_DETAILS, {
    fetchPolicy: 'network-only'
  });

  const {
    user: { email = '', firstName = '', lastName = '', profileImage = '' } = {}
  } = userdata;
  const [updateProfile, { loading: updLoading }] = useMutation(UPDATE_PROFILE, {
    onCompleted() {
      setDisabledprofile(true);
      setDisabled(true);
    },
    refetchQueries: [{ query: GET_USER_DETAILS }]
  });
  const [getSignedURL] = useMutation(GET_SIGNED_URL, {
    async onCompleted(data) {
      const { getSignedUrl: { signedURL = '', fileURL = '' } = {} } = data;

      const options = {
        headers: {
          'Content-Type': currentFile.type,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true,
          'x-amz-acl': 'public-read'
        }
      };

      const uploadFileOnS3 = await axios.put(signedURL, currentFile, options);

      if (uploadFileOnS3.status === 200) {
        updateProfile({
          variables: {
            profileImage: fileURL
          }
        });
      }
    }
  });
  const onFinish = (values) => {
    updateProfile({
      variables: {
        firstName: values.firstname,
        lastName: values.lastname
      }
    });
  };
  const handleuserprofile = () => {
    getSignedURL({
      variables: {
        fileName: currentFile.name,
        fileType: currentFile.type
      }
    });
  };

  const uploadButton = (
    <div>
      <Avatar size={100} icon={<UserOutlined />} />
    </div>
  );

  const onChange = (e) => {
    if (
      e.target.files[0].type === 'image/jpeg' ||
      e.target.files[0].type === 'image/png' ||
      e.target.files[0].type === 'image/jpg'
    ) {
      setCurrentFile(e.target.files[0]);
      // eslint-disable-next-line no-undef
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setfilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      message.error(`${e.target.files[0].name} is not valid type.`);
    }
  };

  const onCancel = () => {
    setfilePreview();
    setDisabledprofile(true);
    setCurrentFile();
  };
  const handleCancel = () => {
    form.resetFields();
    setDisabled(true);
  };

  return (
    <>
      <Row justify="center">
        <Col span={15}>
          <Card
            className="mb-20"
            loading={loading}
            title="User Profile"
            extra={
              <Button
                className="btn-Primary"
                hidden={!disabledprofile}
                onClick={() => setDisabledprofile(false)}
                type="primary"
                htmlType="submit"
              >
                Edit
              </Button>
            }
          >
            <Form
              name="basic"
              onFinish={handleuserprofile}
              initialValues={{ remember: true }}
              layout="vertical"
            >
              <Form.Item label="Profile Image" name="profileimage">
                <label
                  htmlFor="photo-upload"
                  className={`custom-file-upload fas ${
                    disabledprofile ? '' : 'pointer'
                  } `}
                >
                  <div className="img-upload">
                    {!disabledprofile && (
                      <div className="upload-text">+ Upload</div>
                    )}
                    {filePreview || profileImage ? (
                      <img
                        className="profile-image"
                        src={filePreview || profileImage}
                        alt="avatar"
                      />
                    ) : (
                      uploadButton
                    )}
                  </div>
                  <input
                    disabled={disabledprofile}
                    id="photo-upload"
                    type="file"
                    className="d-none"
                    onChange={onChange}
                    accept="image/png, image/jpeg, image/jpg"
                  />
                </label>
              </Form.Item>
              <Form.Item hidden={disabledprofile}>
                <Button
                  htmlType="button"
                  className="btn-secondary-xl "
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="btn-Primary ml-10"
                  loading={updLoading}
                  disabled={!currentFile}
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Card>

          <Card
            className="mb-20"
            loading={loading}
            title="User Information"
            extra={
              <Button
                className="btn-Primary"
                hidden={!disabled}
                onClick={() => setDisabled(false)}
                type="primary"
                htmlType="submit"
              >
                Edit
              </Button>
            }
          >
            <Form
              form={form}
              name="basic"
              initialValues={{
                remember: true
              }}
              onFinish={onFinish}
              layout="vertical"
            >
              <Form.Item
                label="First Name"
                name="firstname"
                rules={[
                  {
                    required: true
                  }
                ]}
                initialValue={firstName}
              >
                <Input disabled={disabled} />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="lastname"
                rules={[
                  {
                    required: true
                  }
                ]}
                initialValue={lastName}
              >
                <Input disabled={disabled} />
              </Form.Item>
              <Form.Item label="Email" name="email" initialValue={email}>
                <Input value={email} disabled />
              </Form.Item>

              <Form.Item hidden={disabled}>
                <Row justify="end" gutter={10}>
                  <Col>
                    <Button
                      htmlType="button"
                      className="btn-secondary-xl "
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      loading={updLoading}
                      className="btn-primary "
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UserProfile;
