import { LeftOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Col, Form, Input, Radio, Row } from 'antd';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/card';
import {
  RESEND_INVITE_MEMBER,
  UPDATE_INVITE_PERMISSION,
  UPDATE_MEMBER_PERMISSION
} from './graphql/Mutations';
import { GET_INVITE_DETAILS, GET_MEMBER_DETAILS } from './graphql/Queries';

const MemberUpdate = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { id: paramsID } = useParams();
  const {
    history: { location: { status = '', workspaceId = '' } = {} } = {}
  } = props;

  const [getMemberByMemberId, { data = {}, loading }] = useLazyQuery(
    GET_MEMBER_DETAILS,
    {
      fetchPolicy: 'network-only'
    }
  );
  const [
    getInviteByInviteId,
    { data: invitedata = {}, loading: inviteloading }
  ] = useLazyQuery(GET_INVITE_DETAILS, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (status === 'ACCEPTED') {
      getMemberByMemberId({
        variables: {
          memberId: paramsID,
          workspaceId: workspaceId
        }
      });
    } else {
      getInviteByInviteId({
        variables: {
          inviteId: paramsID
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [editMember, { loading: editLoading }] = useMutation(
    UPDATE_MEMBER_PERMISSION,
    {
      onCompleted() {
        history.goBack();
      }
    }
  );
  const [resendInvite, { loading: resendLoading }] = useMutation(
    RESEND_INVITE_MEMBER,
    {
      onCompleted() {
        history.goBack();
      }
    }
  );
  const [editInvite, { loading: inviteLoading }] = useMutation(
    UPDATE_INVITE_PERMISSION,
    {
      onCompleted() {
        history.goBack();
      }
    }
  );
  const {
    getMemberByMemberId: {
      email = '',
      permission = '',
      workspaceName = ''
    } = {}
  } = data;
  const {
    getInviteByInviteId: {
      email: inviteemail = '',
      permission: invitepermission = '',
      workspaceName: invitespacename = ''
    } = {}
  } = invitedata;

  const onFinish = (value) => {
    const { permission: Privilege = '' } = value;
    if (status === 'ACCEPTED') {
      editMember({
        variables: {
          memberId: paramsID,
          workspaceId: workspaceId,
          permission: Privilege || permission
        }
      });
    } else if (status === 'REJECTED') {
      resendInvite({
        variables: {
          email: inviteemail,
          workspaceId: workspaceId,
          permission: Privilege || invitepermission
        }
      });
    } else {
      editInvite({
        variables: {
          inviteId: paramsID,
          permission: Privilege || invitepermission
        }
      });
    }
  };

  return (
    <Row justify="center">
      <Col span={15}>
        <Card
          loading={loading || inviteloading}
          title={
            <>
              <Row justify="space-between" align="middle">
                <Col>
                  <Button
                    onClick={() => history.goBack()}
                    className="btn-back"
                    type="text"
                  >
                    <LeftOutlined title="Back" />
                  </Button>
                  <span className="ml-5">
                    {' '}
                    {status === 'REJECTED'
                      ? 'Resend Invitation'
                      : 'Update member'}
                  </span>
                </Col>
              </Row>
            </>
          }
          className="mb-20"
        >
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true
            }}
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item label="Workspace" name="workspace">
              <Input
                suffix={<WalletOutlined />}
                disabled
                defaultValue={workspaceName || invitespacename}
              />
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input
                suffix={<UserOutlined />}
                disabled
                defaultValue={email || inviteemail}
              />
            </Form.Item>
            <Form.Item label="Permission" name="permission">
              <Radio.Group
                defaultValue={permission || invitepermission}
                buttonStyle="solid"
              >
                <Radio.Button value="WRITE">WRITE</Radio.Button>
                <Radio.Button value="READ_ONLY">READ ONLY</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item className="mb-0">
              <Row justify="end" gutter={10}>
                <Col>
                  <Button
                    htmlType="button"
                    className="btn-secondary-xl"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    loading={editLoading || inviteLoading || resendLoading}
                    className="btn-Primary"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default MemberUpdate;
