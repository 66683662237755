import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Form, Input, Modal, Row, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { REGEX } from '../../common/constants';
import Card from '../../components/Card/card';
import CommonLinkTable from '../../components/commonLinkTable/CommonLinkTable';
import { GET_WORKSPACE_DETAILS_BYID } from '../workspace/graphql/Queries';
import { CREATE_LINK } from './graphql/Mutations';
import { GET_LINKS_BYFOLDER } from './graphql/Queries';

const LinkListing = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { getWorkspace, getPermission } = useContext(AppContext);
  const workspaceId = getWorkspace();
  const permission = getPermission();

  const [modalVisible, setModalVisible] = useState(false);
  const [URL, setURL] = useState('');

  const { loading: workspaceLoading, data: workspaceData } = useQuery(
    GET_WORKSPACE_DETAILS_BYID,
    {
      variables: {
        workspaceId: workspaceId
      }
    }
  );

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  const [createLink, { loading }] = useMutation(CREATE_LINK, {
    onCompleted() {
      setModalVisible(false);
    },
    refetchQueries: [{ query: GET_LINKS_BYFOLDER, variables: { folderId: id } }]
  });

  const { data = {}, loading: dataLoading } = useQuery(GET_LINKS_BYFOLDER, {
    fetchPolicy: 'network-only',
    variables: {
      folderId: id
    }
  });

  if (dataLoading || workspaceLoading)
    return (
      <div className="center-element mt-20p">
        <Spin size="large" />
      </div>
    );

  const onFinish = () => {
    createLink({
      variables: {
        URL: URL,
        folderId: id,
        workspaceId: workspaceId
      }
    });
  };

  const handleInputChange = (e) => {
    setURL(e.target.value);
  };

  const { getLinkByFolderId = [] } = data;

  return (
    <>
      <Card>
        <Row justify="space-between" align="middle">
          <Col className="create-link">Create a new link</Col>
          <Col>
            <Button
              disabled={
                permission === 'READ_ONLY' ||
                workspaceData?.getWorkspaceById?.archived
              }
              type="primary"
              htmlType="submit"
              onClick={() => setModalVisible(true)}
            >
              Create a new link
            </Button>
          </Col>
        </Row>
      </Card>
      <Modal
        title="Create a new link"
        centered
        visible={modalVisible}
        onOk={form.submit}
        onCancel={() => setModalVisible(false)}
        okButtonProps={{
          loading: loading,
          className: 'btn-Primary'
        }}
        cancelButtonProps={{ className: 'btn-secondary-xl' }}
      >
        <Form
          form={form}
          id="URL-form"
          name="basic"
          initialValues={{
            remember: true
          }}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            className="mb-0"
            label="Enter URL"
            name="url"
            rules={[
              {
                required: true,
                pattern: REGEX.WEB_URL,
                message: 'Enter valid URL'
              }
            ]}
          >
            <Input
              placeholder="https://yourlink.com"
              onChange={handleInputChange}
            />
          </Form.Item>
        </Form>
      </Modal>
      <CommonLinkTable
        data={getLinkByFolderId}
        id={id}
        dataLoading={dataLoading}
      />
    </>
  );
};

export default LinkListing;
