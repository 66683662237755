import { Col, Row } from 'antd';
import React from 'react';
import Card from '../Card/card';

const CommonAnalyticsCard = ({ hidden, loading, count, label, children }) => {
  return (
    <>
      <div hidden={hidden}>
        <Card loading={loading} hoverable bordered>
          <Row gutter={10}>
            <Col>{children}</Col>
            <Col className="fontsize-20">
              <Row>{label}</Row>
              <Row>
                <b className="link-icon"> {count}</b>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};

export default CommonAnalyticsCard;
