import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_USER_DETAILS = gql`
  {
    user {
      firstName
      lastName
      email
      profileImage
    }
  }
`;
