import { Form, Input, Spin } from 'antd';
import React from 'react';

const CommonFolderForm = ({ inputChange, data, loading, form, onFinish }) => {
  return (
    <Spin size="large" spinning={loading}>
      <Form
        onFinish={onFinish}
        form={form}
        name="basic"
        initialValues={{
          remember: true
        }}
        layout="vertical"
      >
        <Form.Item
          className="mb-0"
          label="Folder Name"
          initialValue={data}
          name="foldername"
          rules={[{ required: true }]}
        >
          <Input placeholder="Folder Name" onChange={inputChange} />
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default CommonFolderForm;
