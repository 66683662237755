import { UserOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row } from 'antd';
import React from 'react';
import Logo from '../../assets/urlTags.svg';
import { formValidatorRules } from '../../common/utils';
import Button from '../Button/Button';
import Card from '../Card/card';

const CommonForm = ({ btnText, onFinish, loading }) => {
  const { required, email } = formValidatorRules;
  return (
    <div className="gx-login-container">
      <Card className="auth-card login-card">
        <Row align="middle" justify="center" className="mb-20">
          <Col>
            <img className="logo-icon" src={Logo} alt="Put your logo here" />
          </Col>
        </Row>
        <div>
          <Form
            layout="vertical"
            onFinish={onFinish}
            className="gx-login-form gx-form-row0"
          >
            <Form.Item label="Email" name="email" rules={[required, email]}>
              <Input suffix={<UserOutlined />} placeholder="Email" />
            </Form.Item>
            <Form.Item className="mb-0">
              <center>
                <Button
                  className="btn-Primary"
                  type="primary"
                  loading={loading}
                  htmlType="submit"
                >
                  {btnText}
                </Button>
              </center>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default CommonForm;
