import { useLazyQuery } from '@apollo/client';
import { Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AppContext } from './AppContext';
import { GET_WORKSPACE_DETAILS_BYID } from './modules/workspace/graphql/Queries';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { getToken, getWorkspace, setPermission } = useContext(AppContext);
  const idToken = getToken();
  const id = getWorkspace();

  const [getWorkspaceById, { loading }] = useLazyQuery(
    GET_WORKSPACE_DETAILS_BYID,
    {
      onCompleted(spacedata) {
        const { getWorkspaceById: { permission = '' } = {} } = spacedata;
        setPermission(permission);
      }
    }
  );
  useEffect(() => {
    if (id) {
      getWorkspaceById({
        variables: {
          workspaceId: id
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <div className="center-element mt-20p">
        <Spin size="large" />
      </div>
    );

  return (
    <Route
      {...rest}
      render={(props) =>
        !idToken ? <Redirect to="/login" /> : <Component {...props} />
      }
    />
  );
};
export default PrivateRoute;
