import { Layout, Menu } from 'antd';
import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import Folders from '../../../modules/workspace/Folders';

const { Sider } = Layout;

function Sidebar({ history }) {
  const { getWorkspace } = useContext(AppContext);
  const workspaceid = getWorkspace();

  if (!workspaceid) {
    history.push('/');
  }

  return (
    <Sider trigger={null} collapsible breakpoint="lg" collapsedWidth="0">
      <Menu>
        <Folders />
      </Menu>
    </Sider>
  );
}

export default withRouter(Sidebar);
